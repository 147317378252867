export const environment = {
  production: true,
  urlApi: "https://finplan-inteligente-testing.appspot.com/",
  fastLinkUrl: "https://fl4.preprod.yodlee.com/authenticate/development-240/fastlink/?channelAppName=tieredpreprod", //Dev
  // fastLinkUrl: "https://fl4.prod.yodlee.com/authenticate/production-282/fastlink/?channelAppName=tieredprod", //Prod
  captchaSiteKey:"6LfqUNUZAAAAAGYtiDoVnzzAxOYQC9T5g9ZaOCMW",
  isApp: false,
  gaTrackingID: "UA-149704695-1",
  urlBrowserApp:  "http://finplan-inteligente-testing.web.app",
  firebase: {
    apiKey: "AIzaSyCGCHKLLS9dJbi4kME_-UExi6_rXtP6b_w",
    authDomain: "finplan-inteligente-testing.firebaseapp.com",
    databaseURL: "https://finplan-inteligente-testing.firebaseio.com",
    projectId: "finplan-inteligente-testing",
    storageBucket: "finplan-inteligente-testing.appspot.com",
    messagingSenderId: "273566267415",
    appId: "1:273566267415:web:4cba873e73706f41638452"
  },
  parameters:{
    email:"support@finplaninteligente.com",
    facebook: "https://www.facebook.com/",
    twitter: "https://www.twitter.com/",
    instagram: "https://www.instagram.com/",
    youtube: "https://www.youtube.com/",
    tiktok:"https://www.tiktok.com",
    url_video_30_54: "https://www.youtube.com/embed/pmUDtMWH56U",
    url_video_55_70: "https://www.youtube.com/embed/86B9x65TOEI",
    url_video_70: "https://www.youtube.com/embed/-_O-jatOQJM",
    google_play: "https://play.google.com/",
    app_store: "https://apple.com/app-store/",
    slide_text_1_es: "Controla tus finanzas personales creando un presupuesto ya sea sincronizando tus cuentas financieras o de forma manual. ¡Todo en un solo lugar!",
    slide_text_2_es: "Educación Financiera para empoderar nuestra comunidad hispana.",
    slide_text_3_es: "¿Estás listo para tomar control de tus finanzas y tomar decisiones financieras inteligentes? ¿Cuál es su plan? ¿Cómo lo financiarás?",
    slide_text_1_en: "Manage personal finances through budgeting.  Create a budget manually or by synchronizing your financial accounts. All in one place!",
    slide_text_2_en: "Financial Literacy to empower our Hispanic community.",
    slide_text_3_en: "Are you ready to take control of your finances and make intelligent financial decisions? What is your plan? How will you fund it?",
    show_captcha_front: true,
    captcha_attemps_front: 5,
    wordpress_url:"https://finplaninteligente.com",
    pricing_monthly:9.99,
    pricing_annual:80,
    pricing_annual_savings:40,
    time: 0
  }
};
