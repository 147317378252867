import { Component, OnInit, ViewChild } from '@angular/core';
import { Platform, IonMenu, MenuController, NavController, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AuthService } from './services/auth.service';
import { environment } from './../environments/environment';
import { TransactionApi } from './services/api/transaction.api';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './services/alert.service';
import { UserApi } from './services/api/user.api';
import { StorageService } from './services/storage.service';
import { PushNotificationService } from './services/push-notification.service';
import { ParametersService } from './services/parameters.service';
import { LoadDataService } from './services/load-data.service'; 

import { Browser } from '@capacitor/browser';
import { lastValueFrom } from 'rxjs';
//const { Browser } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  pushNotification!:PushNotificationService;
  public selectedIndex = -1;
  public appPages = [
    {
      title: 'home',
      url: '/home',
      icon: 'Inicio.png',
      isSingin:false,
      isLogout:true
    },
    {
      title: 'home',
      url: '/dashboard',
      icon: 'Inicio.png',
      isSingin:true,
      isLogout:false
    },
    {
      title: 'my_balance',
      url: '/balance',
      icon: 'balance.png',
      isSingin:true,
      isLogout:false
    },
    {
      title: 'budgets',
      url: '/budget',
      icon: 'presupuesto.png',
      isSingin:true,
      isLogout:false
    },
    {
      title: 'transactions',
      url: '/transaction',
      icon: 'transacciones.png',
      isSingin:true,
      isLogout:false
    },
    {
      title: 'categories',
      url: '/categories',
      icon: 'Categoria.png',
      isSingin:true,
      isLogout:false
    },
    /* 
    {
      title: 'bank_accounts',
      url: '/bank-accounts',
      icon: 'cuentas-de-banco.png',
      isSingin:true,
      isLogout:false
    },*/
    
    /*{
      title: 'debts',
      url: '/debts',
      icon: 'icono-bolsa.png',
      isSingin:true,
      isLogout:false
    },
    {
      title: 'rules',
      url: '/rules',
      icon: 'reglas.png',
      isSingin:true,
      isLogout:false
    },
    {
      title: 'subscription',
      url: '/subscription',
      icon: 'pago.png',
      isSingin:true,
      isLogout:false,
      external:environment.isApp
    }, */
    {
      title: 'edit_account',
      url: '/profile',
      icon: 'editat-cuenta.png',
      isSingin:true,
      isLogout:false
    },
    {
      title: 'change_password',
      url: '/change-password', 
      icon: 'cambiar-contrasenna.png',
      isSingin:true,
      isLogout:false
    }, 
    {
      title: 'access',
      url: '/login',
      icon: 'acceder.png',
      isSingin:false,
      isLogout:true
    },
    {
      title: 'register',
      url: '/signup1',
      icon: 'Regisrarse.png',
      isSingin:false,
      isLogout:true
    },
    /* {
       title: 'pricing',
       url: '/pricing',
       icon: 'pago.png',
       isSingin:false,
       isLogout:false
    } , */
    {
      title: 'eresfpi',
      url: '/eresfpi',
      icon: 'blog.png',
      isSingin:true,
      isLogout:true
    },
    {
      title: 'about_me',
      url: '/about',
      icon: 'quienes-somos.png',
      isSingin:true,
      isLogout:true
    },
    {
      title: 'terms_of_use',
      url: '/terms',
      icon: 'terminos-de-usa.png',
      isSingin:true,
      isLogout:true
    },
    /* {
      title: 'technical_support',
      url: 'mailto:support@finplaninteligente.com',
      icon: 'privacidad.png',
      isSingin:true,
      isLogout:true
    }, */
    {
      title: 'privacy',
      url: '/privacy',
      icon: 'privacidad.png',
      isSingin:true,
      isLogout:true
    } ,
    /*{
      title: 'faqs',
      url: '/faqs',
      icon: 'FAQS.png',
      isSingin:true,
      isLogout:true,
      external:environment.isApp
    }, */
  ];

  parameters:any = {
    email:"support@finplaninteligente.com",
    facebook: "https://www.facebook.com/",
    twitter: "https://www.twitter.com/",
    instagram: "https://www.instagram.com/",
    youtube: "https://www.youtube.com/",
    tiktok: "https://www.tiktok.com/"
  };

  @ViewChild('menu', { static: true }) menu!: IonMenu;
  width!:number;
  fotoUser!:string;
  nameUser:string = "*******";
  flag:string = "./assets/img/eu.png";
  hiddenMenu:boolean = true;
  dayTrial:string = "0";

  backgroundDesktop:boolean = false;
  backgroundMovil:boolean = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public authService: AuthService,
    private transactionApi: TransactionApi,
    public menuCtrl: MenuController,
    private navCtrl:NavController,
    public translate: TranslateService,
    private loadingCtrl:LoadingController,
    private alertService:AlertService,
    private storageService: StorageService,
    private userApi: UserApi,
    private pushNotificationService: PushNotificationService,
    private parametersService: ParametersService,
    private loadData: LoadDataService,
  ) {
    this.storageService.getString('IdInvitado').then( valor => {
      if( valor.value )
        this.authService.IdInvitado = valor.value;
      else{
        let tmptmp = new Date().getTime()
        this.authService.IdInvitado = "Invitado" + tmptmp.toString();
        this.storageService.setString('IdInvitado', this.authService.IdInvitado);
      }
    });
    
    this.addCSSBackground( this.platform.width() <= 992 ? 'movil' : 'desktop' );

    // if( Capacitor.isPluginAvailable('StatusBar') ){
    //   StatusBar.setStyle({style:StatusBarStyle.Light});
    //   StatusBar.setOverlaysWebView({ overlay: false });
    //   StatusBar.show();
    // }

    this.parametersService.sync().subscribe( {next:val => {
      this.parameters = val;
      let diff = new Date().getTime() - val.time;
      AuthService.timeDiffServer = diff;
    }, error: e => {}});

    this.authService.initLang();
    // let self = this;
    // this.storageService.getString('lang').then( val => {
    //     if( !val.value ){
    //       self.globalization.getPreferredLanguage()
    //       .then(res => {
    //           self.authService.setLang( res.value.toLowerCase() )  
    //       })
    //       .catch(e => console.log(e));
    //     }
    // });
    
    this.initializeApp();
  }
  
  fondoImagen:string ="";
  fondoImagenMovil:string ="";
  fondoClaro:string ="";
  fondoClaroMovil:string ="";
  addCSSBackground( typ: string){
    if( typ == 'movil' && !this.backgroundMovil ){
      this.backgroundMovil = true;
      // let img2 = this.loadData.loadImage('./assets/img/fondo-imagen-movil.jpg').then( x =>{ 
      //   this.fondoImagenMovil = "url('" + x + "')";
      // });
      // let img4 = this.loadData.loadImage('./assets/img/fondo-claro.jpg').then( x =>{ 
      //   this.fondoClaroMovil = "url('" + x + "')";
      // });
  
      let style = document.createElement('style');
      style.setAttribute("type", 'text/css');
  
      // Promise.all([img2, img4]).then( () => {
        let css =   `
          .contentMovil, .contentOnlyMovil{
              --background: url("./assets/img/fondo-imagen-movil.jpg") no-repeat center center / cover;
          }
          .contentMovilClaro{
              --background: url("./assets/img/fondo-claro.jpg") no-repeat center center / cover;
          }`;
        // let css =   `
        //   .contentMovil, .contentOnlyMovil{
        //       --background: ${this.fondoImagenMovil} no-repeat center center / cover;
        //   }
        //   .contentMovilClaro{
        //       --background: ${this.fondoClaroMovil} no-repeat center center / cover;
        //   }`;
          style.innerHTML = css;
          // console.log(document.querySelector('head'));
          document.getElementsByTagName('head')[0].appendChild(style);
      // });
    }else
    if( typ != 'movil' &&  ! this.backgroundDesktop ){
      this.backgroundDesktop = true;
      /* let img1 = this.loadData.loadImage('./assets/img/fondo-imagen.png').then( x =>{ 
        this.fondoImagen = "url('" + x + "')";
      });
      let img3 = this.loadData.loadImage('./assets/img/fondo-imagen-claro.jpg').then( x =>{ 
        this.fondoClaro = "url('" + x + "')";
      }); */

      let style = document.createElement('style');
      style.setAttribute("type", 'text/css');

      //Promise.all([img1, img3]).then( () => {
        let css =   `
          .image-overlay{
            background-image: url("./assets/img/fondo-imagen.png");
          }        
          @media (min-width:992px) {
              .contentMovil, .contentMovilClaro, .contentOnlyMovil{
                  --offset-top: 0px !important;
              }
              .contentMovil{
                  --background: url("./assets/img/fondo-imagen.png") no-repeat center center / cover;
              }
              
              .contentMovilClaro{
                  --background: url("./assets/img/fondo-imagen-claro.jpg") no-repeat center center / cover;
              }
          
              .contentOnlyMovil{
                  --background: none;
              }
          }`;
          style.innerHTML = css;
          document.getElementsByTagName('head')[0].appendChild(style);
      //}, error => console.log(error))
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.overlaysWebView(false);
      this.statusBar.styleLightContent();
      this.statusBar.backgroundColorByHexString("#000");
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    let self = this;
    self.width = self.platform.width();
    this.authService.getToken().then( val => {
      if( this.authService.isLoggedIn ){
        this.pushNotificationService.register();
        self.userApi.get().subscribe({next:(val: any )=>{
          self.authService.setUser( val.data );
        }, error: (e) => {
          self.authService.valideAuth(e);
        }});
      }

      self.setDataUser();
      self.stateMenu();
      self.platform.resize.subscribe(async () => {
        self.width = self.platform.width();
        this.addCSSBackground( self.width <= 992 ? 'movil' : 'desktop' );
        self.stateMenu();
      });
     
    });

    this.authService.replaySubject.subscribe(val=>{
      if(val.type == "updateUser")
          this.setDataUser();
    })

    this.menuCtrl.enable(false);
  }

  setDataUser(){
    if( this.authService.storage && this.authService.storage.data && this.authService.storage.data.trial ){
      let now = new Date().getTime() - (<number>AuthService.timeDiffServer);
      let expired = new Date(this.authService.storage.data.expired + "T23:59:59").getTime()
      let dayLeft = Math.max(expired - now, 0)/(1000*60*60*24);
      this.dayTrial = dayLeft.toFixed()
    }
    
    this.stateMenuBoolean();
    this.fotoUser = "./assets/img/Usuario.png";
    if( this.authService.isLoggedIn ){
      if( this.authService.storage.data.image && this.authService.storage.data.image != 'None' )
        this.fotoUser = this.authService.storage.data.image;

      if( this.authService.storage.data.firstName && this.authService.storage.data.firstName  != 'None' )  
        this.nameUser = this.authService.storage.data.firstName;
      else
        this.nameUser = this.authService.storage.data.email;
      
        if( this.authService.storage.data.flag && this.authService.storage.data.flag != '' )
          this.flag = this.authService.storage.data.flag;
    }
  }

  stateMenuBoolean(){
    if(!this.authService.isLoggedIn && this.width <= 992){
      this.hiddenMenu = false;
      return false;
    }

    if( !this.authService.storage || !this.authService.storage.data.completeSignUp || this.userExpired() ){
      this.hiddenMenu = true;
      this.menuCtrl.get('prin').then((menu) => {
        (<HTMLIonMenuElement>menu).style.display = 'none';
      });
      return true;
    }

    this.hiddenMenu = false;
    return false
  }

  stateMenu(){
    if( this.stateMenuBoolean() )
      this.menuCtrl.enable(false);
    else
      this.menuCtrl.enable(true);
  }

  userExpired(){
    let now = new Date().getTime() - (<number>AuthService.timeDiffServer);

    //Esto debe ser descomentado y devolverlo en el return para la suscripciones de pago
    //( !this.authService.storage ||  !this.authService.storage.data.expired || this.authService.storage.data.expired=='' || new Date(this.authService.storage.data.expired + "T23:59:59").getTime() < now );
    return false;
  }

  pagesShow(){
    let pages = [];
    const path = window.location.pathname;
    let ptr = 0;

    for( let p of this.appPages ){
      if( this.width >= 993 &&  ['/change-password', '/profile'].includes(p.url) )
        continue;

      if( ['/subscription'].includes(p.url) && ( !this.authService.storage || this.authService.storage.data.trial ) )
        continue;

      if( ( p.isSingin && this.authService.isLoggedIn ) ||  ( p.isLogout && !this.authService.isLoggedIn ) ){
        pages.push(p);
        if( p.url.toLowerCase() === path.toLowerCase() )
          this.selectedIndex = ptr;
        ptr += 1;
      }
    }

    return pages;
  }

  async syncAccounts(){
    let msgLoading = await lastValueFrom(this.translate.get("loading").pipe());
    let loading = await this.loadingCtrl.create( { message:msgLoading } );
    await loading.present();
    this.transactionApi.sync().subscribe({ next:(value: any) => {  
      this.alertService.presentToast(value.message);
      loading.dismiss(); 
    }, error: (err: any) => {
      this.authService.valideAuth( err );
      this.alertService.presentToast(err.message);
      loading.dismiss();
    } });
  };

  closeMenu(){
    this.menuCtrl.toggle();
  }

  logout(){
    this.authService.logout();
    this.menuCtrl.enable(false);
    this.navCtrl.navigateRoot("home");
  }

  setLang(l: any){
    this.authService.setLang(l);
  }

  onClickMenu(action: string){
    if( action == "subscription" ){
      let url = environment.urlBrowserApp + "/redirect?a="+btoa(action)+"&b=" + btoa(JSON.stringify(this.authService.storage));
      Browser.open({
        url: url
      });
    }
  }

  subscribeNow(){
    this.navCtrl.navigateForward("plan");
  }
}
